.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .mainCard {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    background-color: #fbfbfd;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .boxCard {
    position: relative;
    width: 100%;
    max-width: 500px;
    height: 600px;
    background-color: #fff;
    border-radius: 2rem;
    box-shadow: 0 0px 40px 5px rgba(0, 0, 0, 0.20);
  }
  
  .innerCard {
    position: absolute;
    width: calc(100% - 4.1rem);
    height: calc(100% - 4.1rem);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .formsWrapCard {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      transition: 0.8s ease-in-out;
      padding: 10px;
  }
  
  .clickhere:hover {
    color: #17a2b8;
    cursor: pointer;
  }
  
  .loginInput {
    width: 100%;
    height: 53px;
    padding: 17px;
    font-size: medium;
    border: solid 1px #919191;
    border-radius: 13px;
  }
  
  .loginInput.active {
    outline: none;
    border-color: #17a2b8;
    box-shadow: 0 0 3px #72c6d4; 
  }
  
  .loginInput:focus {
    outline: none;
    border: solid 1px #17a2b8;
    box-shadow: 0 0 3px #72c6d4;
  }
  
  .loginBtn {
    background: linear-gradient(to right, #17a2b8, #2c81ae);
    color: white;
    width: 100%;
    padding: 17px;
    margin-top: 10px;
    border: solid 1px #29a9bd;
    border-radius: 13px;
    font-size: medium;
    cursor: pointer;
  }
  
  .loginBtn:hover {
    background: linear-gradient(to left, #2c81ae, #2c81ae);
  }
  
  .bottom-text {
    display: flex;
  }
  
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f2f2f7;
    color: #b3b3b6;
    text-align: center;
    padding: 20px;
    font-size: small;
    display: flex;
    justify-content: space-around;
  
  }
  
  
  